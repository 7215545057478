import React from "react";
import "./App.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /** Create Apollo Client with Authentication Header */

  /**
   * creates the http link with authorization header from session storage
   */
  const httpLinkBase = createHttpLink({
    uri: process.env.GQL_HTTP_URI,
  });
  const httpAuthLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const userJwt = localStorage.getItem("userJwt");
    return {
      headers: {
        ...headers,
        authorization: userJwt ? `Bearer ${userJwt}` : "",
      },
    };
  });
  const httpLink = httpAuthLink.concat(httpLinkBase);

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return <Provider client={client}>{children}</Provider>;
};
