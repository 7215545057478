import React from "react";
import * as styles from "./Home.style";

export const Home = () => {
  return (
    <div className={styles.main}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Shake Portal Home</div>
      </div>
    </div>
  );
};
