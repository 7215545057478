import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * USER
   */
  GET_USERS_SUCCESS = "USER/GET_USERS_SUCCESS",
  UPDATE_USER_SUCCESS = "USER/UPDATE_USER_SUCCESS",
}

export const GetUsersSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_USERS_SUCCESS,
  {
    users: {
      nodes: Models.Users.User[];
    };
    hasNext: boolean;
    nextCursor?: string;
  }
> = ActionModels.createActionCreator(ActionTypes.GET_USERS_SUCCESS);

export const UpdateUserSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_USER_SUCCESS,
  Partial<Models.Users.User> & { id: number }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_USER_SUCCESS);

export type Actions = ReturnType<
  typeof GetUsersSuccess | typeof UpdateUserSuccess
>;
