import * as React from "react";
import * as styles from "./Header.style";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { Link, useLocation } from "react-router-dom";

export const Header = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.header}>
      <div className={styles.routes}>
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
          <JwtTracker />
        </SignedIn>
        <div>Shake Admin Portal</div>
      </div>
      <div className={styles.routes}>
        <Link
          to="/"
          className={`${styles.route} ${
            pathname == "/" ? styles.activeRoute : ""
          }`}
        >
          Home
        </Link>
        <Link
          to="/users"
          className={`${styles.route} ${
            pathname == "/users" ? styles.activeRoute : ""
          }`}
        >
          Users
        </Link>
      </div>
    </div>
  );
};

export const JwtTracker: React.FC = () => {
  const { getToken } = useAuth();
  const { user } = useUser();
  React.useEffect(() => {
    if (user) {
      getToken({ template: "jwt_with_email" }).then((res) => {
        console.log("JWT Token::\n", res);
        if (res && !!localStorage) {
          localStorage.setItem("userJwt", res);
        }
      });
    }
  }, [user, localStorage]);

  return null;
};
