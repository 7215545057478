import { gql } from "@apollo/client";

export const GET_USERS_QUERY = gql`
  query adminGetUsers($take: Int!, $skip: Int!) {
    adminGetUsers(take: $take, skip: $skip) {
      id
      email
      accountStatus
      testingAccount
      userProfile {
        firstName
        lastName
        cellPhoneNumber
      }
      userDate {
        createdAtUtc
        lastSignInUtc
        updatedAtUtc
      }
    }
  }
`;
