import React from "react";
import * as styles from "./Users.style";
import { UsersTable } from "./UsersTable";

export const Users = () => {
  return (
    <div className={styles.component}>
      <UsersTable />
    </div>
  );
};
