"use client";
import * as React from "react";
import styles from "./UsersTable.module.css";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";

export const UsersTable = () => {
  const [users, setUsers] = React.useState<any[]>([]);
  const client = useApolloClient();

  const getUsers = () => {
    client
      .query({
        query: Query.GET_USERS_QUERY,
        variables: {
          take: 10,
          skip: users.length,
        },
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.adminGetUsers?.length) {
          setUsers(res.data.adminGetUsers);
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={styles.component}>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <div className={styles.tableHeaderItem}>User Id</div>
          <div className={styles.tableHeaderItemLarge}>Email</div>
          <div className={styles.tableHeaderItem}>First Name</div>
          <div className={styles.tableHeaderItem}>Last Name</div>
          <div className={styles.tableHeaderItem}>Cell</div>
          <div className={styles.tableHeaderItem}>Account Status</div>
        </div>
        {users.map((user) => (
          <div className={styles.tableRow} key={user.id}>
            <div className={styles.tableRowItem}>{user.id}</div>
            <div className={styles.tableRowItemLarge}>
              <div style={{ textWrap: "wrap", maxWidth: 150 }}>
                {user.email.slice(0, 12)}...
              </div>
            </div>
            <div className={styles.tableRowItem}>
              {user.userProfile.firstName}
            </div>
            <div className={styles.tableRowItem}>
              {user.userProfile.lastName}
            </div>
            <div className={styles.tableRowItem}>
              {user.userProfile.cellPhoneNumber}
            </div>
            <div className={styles.tableRowItem}>{user.accountStatus}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
