// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K41cOcUPZKwTIYh5jY_l {
  padding: 12px 24px;
}

.zaxAS0KdCxCBEFB3wmsD {
  border: 1px solid black;
  border-bottom-width: 0;
}

.iS6FgBq3IY61t7QQ4Re1 {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
}

.bBeYjjuwrg4egEg35BCG {
  flex: 2;
  padding: 4px 8px;
  font-size: 16;
  font-weight: 600;
}

.SBm2KSBTg8CqjbvVWkbR {
  flex: 3;
  padding: 4px 8px;
  font-size: 16;
  font-weight: 600;
}

.Ag5Ji12Kai7gkSDzq0Lx {
  flex: 2;
  padding: 4px 8px;
  font-size: 14;
  font-weight: 400;
}

.BGdg86iXCq9XBOeo4MBw {
  flex: 3;
  padding: 4px 8px;
  font-size: 14;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./pages/Users/UsersTable/UsersTable.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".component {\n  padding: 12px 24px;\n}\n\n.table {\n  border: 1px solid black;\n  border-bottom-width: 0;\n}\n\n.tableRow {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid black;\n}\n\n.tableHeaderItem {\n  flex: 2;\n  padding: 4px 8px;\n  font-size: 16;\n  font-weight: 600;\n}\n\n.tableHeaderItemLarge {\n  flex: 3;\n  padding: 4px 8px;\n  font-size: 16;\n  font-weight: 600;\n}\n\n.tableRowItem {\n  flex: 2;\n  padding: 4px 8px;\n  font-size: 14;\n  font-weight: 400;\n}\n\n.tableRowItemLarge {\n  flex: 3;\n  padding: 4px 8px;\n  font-size: 14;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": `K41cOcUPZKwTIYh5jY_l`,
	"table": `zaxAS0KdCxCBEFB3wmsD`,
	"tableRow": `iS6FgBq3IY61t7QQ4Re1`,
	"tableHeaderItem": `bBeYjjuwrg4egEg35BCG`,
	"tableHeaderItemLarge": `SBm2KSBTg8CqjbvVWkbR`,
	"tableRowItem": `Ag5Ji12Kai7gkSDzq0Lx`,
	"tableRowItemLarge": `BGdg86iXCq9XBOeo4MBw`
};
export default ___CSS_LOADER_EXPORT___;
