import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const header = s({
  padding: "12px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const routes = s({
  display: "flex",
  justifyContent: "space-between",
  gap: 12,
  alignItems: "center",
});

export const route = s({
  fontSize: 16,
  fontWeight: 300,
  textTransform: "uppercase",
  $nest: {
    "&:hover": {
      color: Styles.colors.blue,
      fontWeight: 500,
      cursor: "pointer",
    },
  },
});

export const activeRoute = s({
  fontWeight: 500,
  $nest: {
    "&:hover": {
      color: "inherit",
      cursor: "default",
    },
  },
});

// .route:hover {
//   color: var(--blue);
//   font-weight: 500;
//   cursor: pointer;
// }

// .activeRoute {
//   font-weight: 500;
// }

// .activeRoute:hover {
//   color: inherit;
//   cursor: default;
// }
