import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const main = s({
  minHeight: "100vh",
  minWidth: "100vw",
  padding: "48px 96px",
  display: "flex",
  justifyContent: "center",
});

export const titleContainer = s({
  display: "flex",
  justifyContent: "center",
  padding: 24,
});

export const title = s({
  textAlign: "center",
  fontSize: 40,
  fontWeight: 600,
  marginBottom: 24,
});
