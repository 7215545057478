import React from "react";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./configureStore";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DisplayContextProvider } from "./DisplayContext";
import { ApolloProvider } from "./ApolloProvider";
import * as Pages from "../pages";
import * as Components from "../components";
import { ClerkProvider } from "@clerk/clerk-react";

// Create Redux Store
const store = getStore();

// Handle Routing
const Page: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/users" element={<Pages.Users />} />
      </Routes>
    </div>
  );
};

const PUBLISHABLE_KEY = process.env.CLERK_PUBLISHABLE_KEY ?? "";

export function App() {
  console.log(PUBLISHABLE_KEY);
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <ApolloProvider>
        <Provider store={store}>
          <HashRouter>
            <DisplayContextProvider>
              <Components.Header />
              <div className="App">
                <Page />
              </div>
            </DisplayContextProvider>
            <ToastContainer
              autoClose={5000}
              position="top-right"
              newestOnTop={false}
              hideProgressBar={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
            />
          </HashRouter>
        </Provider>
      </ApolloProvider>
    </ClerkProvider>
  );
}
